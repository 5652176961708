import React from 'react';
import { Link, graphql } from "gatsby"
import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"
import Layout from "../components/layout"
import {Helmet} from "react-helmet"
import Seo from "../components/seo"

  

const About = ({ data, location }) => {
    const siteTitle = data.site.siteMetadata.title
    const post = data.post
  return (
    <Layout location={location} title={siteTitle}>
    <Helmet>
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.min.js" integrity="sha384-cuYeSxntonz0PPNlHhBs68uyIAVpIIOZZ5JqeqvYYIcEL727kskC66kF92t6Xl2V" crossorigin="anonymous"></script>
    </Helmet>
    <Seo title={`${post.title}`} />
    <article
      className="global-wrapper blog-post"
      itemScope
      itemType="http://schema.org/Article"
    >
    <h1 itemProp="headline">{post.title}</h1>
        <section itemProp="articleBody">
            <div dangerouslySetInnerHTML={{__html:post.text}} />
        </section>
    </article>
    </Layout>
  )
}

export default About


export const pageQuery = graphql`
  query BlogPostById {
    site {
      siteMetadata {
        title
      }
    }
    post: mysqlCms(slug: { eq: "sobre-nosaltres" }) {
      id
      title
      slug
      text
    }
  }
`

